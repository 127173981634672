import { Container, Grid } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { getCoinBalances, getCoinPrices } from '../../helpers/coinHelper';
import * as SettingActions from '../../store/setting/setting.actions';
import Footer from '../base/Footer';
import NavBar from '../base/NavBar';
import TitleBar from '../base/TitleBar';
import WalletInfo from '../users/walletInfo';
import RecentDetail from './RecentDetail';
import RecentList from './RecentList';

export default function DashboardContainer() {
    const [mode, setMode] = useState("");
    const [selectedItem, setSelectedItem] = useState("");
    const { adminSetting } = useSelector((response) => {
        return {
            adminSetting: response.SettingReducer.adminSetting
        }
    });
    const dispatch = useDispatch();
    const [coinPrices, setCoinPrices] = useState([]);
    const [coinBalance, setCoinBalance] = useState([]);

    useEffect(() => {
        dispatch(SettingActions.loadSettingRequest());
        dispatch(SettingActions.loadAdminRequest());
        dispatch(SettingActions.fetchRecentRequest());
    }, []);

    const { users, recents } = useSelector((state) => {
        return {
            users: state.UserReducer.Users,
            recents: state.SettingReducer.recents
        }
    })

    useEffect(() => {
        getCoinPrices().then((x) => setCoinPrices(x));
        if (adminSetting.wallets) {
            getCoinBalances(adminSetting.wallets).then((x) => {
                setCoinBalance(x);
            });
        }
    }, [adminSetting])

    return (
        <div>
            <NavBar
                pageName="Dashboard"
                children={
                    <Container maxWidth={false} >
                        <TitleBar heading="Dashboard" secondary={"Crypto Users and Wallets"}
                            icon={<HelpOutlineIcon />} />
                        <Grid container spacing={2} >
                            <Grid item md={12}>
                                <RecentList
                                    withdraws={_.sortBy(recents, item => moment.unix(item.createdOn._seconds)).reverse()}
                                    onDetailsOpen={(user) => {
                                        setMode("details");
                                        setSelectedItem(user);
                                    }}
                                    users={users} />
                                <RecentDetail
                                    open={mode === "details"}
                                    withdraw={selectedItem}
                                    users={users}
                                    onUpdate={() => {
                                        setMode("list");
                                        setSelectedItem({});
                                    }}
                                    onClose={() => {
                                        setMode("list");
                                        setSelectedItem({});
                                    }
                                    }
                                />
                            </Grid>
                            {
                                adminSetting && adminSetting.wallets && adminSetting.wallets.map((x) => {
                                    return <Grid item md={6}>
                                        <WalletInfo
                                            key={x.walletId}
                                            wallet={x}
                                            coinPrices={coinPrices}
                                            coinBalance={coinBalance}
                                        />
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Container>
                }
            />
            <Footer />
        </div >
    );
}





