import { call, put, takeEvery } from 'redux-saga/effects';
import * as AuthActions from './auth.actions';
import AuthAPI from './auth.api';
import { AuthenticationActionTypes } from './auth.model';

export function* handleLogin(action) {
  try {
    const response = yield call(AuthAPI.Login, action.payload);
    yield put(AuthActions.loginSuccess(response));
  } catch (e) {
    yield put(AuthActions.loginFailure(e));
  }
}

export function* handleLogout(action) {
  try {
    const response = yield call(AuthAPI.Logout, action.payload);
    yield put(AuthActions.logoutSuccess(response));
  } catch (e) {
    yield put(AuthActions.logoutFailure(e));
  }
}

export function* AuthSaga() {
  yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
  yield takeEvery(AuthenticationActionTypes.LOGOUT_REQUEST, handleLogout);
}

