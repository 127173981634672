import { Container, FormControl, FormLabel, Grid, MenuItem, Select, makeStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IsNullOrEmpty } from '../../helpers/utility';
import * as SettingActions from '../../store/setting/setting.actions';
import * as UserActions from '../../store/user/user.actions';
import Footer from '../base/Footer';
import NavBar from '../base/NavBar';
import TitleBar from '../base/TitleBar';
import DepositDetail from './DepositDetail';
import DepositList from './DepositList';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 2)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(7),
        right: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
}));

export default function DepositContainer() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [mode, setMode] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [selectedItem, setSelectedItem] = useState("");
    const [coinType, setCoinType] = useState(undefined);
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        dispatch(UserActions.loadUsersRequest());
    }, []);

    const { users, deposits } = useSelector((state) => {
        return {
            users: state.UserReducer.Users,
            deposits: state.SettingReducer.deposits
        }
    })

    return (
        <div>
            <NavBar
                pageName="Dashboard"
                children={
                    <Container maxWidth={false} >
                        <TitleBar heading="Deposits" secondary={"List of Deposits"}
                            icon={<HelpOutlineIcon />} />
                        <Grid container spacing={2} >
                            <Grid item md={6}>
                                <FormControl variant='outlined' className={classes.formControl}>
                                    <FormLabel component="legend" className='my-1'>Coin Type</FormLabel>
                                    <Select
                                        placeholder="Select Coin Type"
                                        name="coinType"
                                        value={coinType}
                                        margin='dense'
                                        onChange={(e) => {
                                            setCoinType(e.target.value);
                                            if (!IsNullOrEmpty(user)) {
                                                dispatch(SettingActions.loadDepositsRequest(e.target.value, user, moment().format()));
                                            }
                                        }}
                                        fullWidth
                                        labelId="coinType"
                                        id="coinType"
                                        displayEmpty
                                    >
                                        <MenuItem value={undefined}>Select Coin Type</MenuItem>
                                        {
                                            ["TRX", "BTC", "BCH", "LTC", "XRP", "ETH", "BNB"].map((x) => {
                                                return <MenuItem value={x}>{x}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <FormLabel component="legend" className='my-1'>User</FormLabel>
                                    <Select
                                        name="user"
                                        value={user}
                                        onChange={(e) => {
                                            setUser(e.target.value);
                                            if (!IsNullOrEmpty(coinType)) {
                                                dispatch(SettingActions.loadDepositsRequest(coinType, e.target.value, moment().format()));
                                            }
                                        }}
                                        fullWidth
                                        labelId="user"
                                        id="user"
                                        margin='dense'
                                        displayEmpty
                                    >
                                        <MenuItem value={undefined}>Select User</MenuItem>
                                        {
                                            users.map((x) => {
                                                return <MenuItem value={x.id}>{x.tenantName}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <DepositList
                                    deposits={deposits}
                                    onDetailsOpen={(user) => {
                                        setMode("details");
                                        setSelectedItem(user);
                                    }}
                                    users={users} />
                                <DepositDetail
                                    open={mode === "details"}
                                    deposit={selectedItem}
                                    users={users}
                                    onUpdate={() => {
                                        setMode("list");
                                        setSelectedItem({});
                                    }}
                                    onClose={() => {
                                        setMode("list");
                                        setSelectedItem({});
                                    }
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Container>
                }
            />
            <Footer />
        </div >
    );
}

