import { Avatar, CardHeader, InputAdornment, TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import QRCode from 'qrcode';
import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { getBlockChainUrl, getLogo } from '../../helpers/utility';
import * as Toastr from 'toastr';
import '../../assets/css/toastr.min.css';
import { OpenInBrowser, OpenInNew } from '@material-ui/icons';

export default function WalletInfo(props) {

    // let balance = props.coinBalance.filter((x) => x.CoinType.toUpperCase() === props.wallet.currency.toUpperCase());
    // let total = 0;
    // if (balance.length > 0) {
    //     let price = props.coinPrices.filter((x) => x.symbol.toUpperCase() === props.wallet.currency.toUpperCase());
    //     balance = balance[0].CoinBalance;
    //     if (price.length > 0) {
    //         total = (balance * price[0].price).toFixed(3);
    //     }
    //     else {
    //         balance = 0;
    //     }
    // }
    // else {
    //     balance = 0;
    // }

    function CopyMe(id) {
        /* Get the text field */
        var copyText = document.getElementById(id);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        Toastr.success(copyText.value, "Copied to clipboard");
    }

    return (
        <Card elevation={10}>
            <CardHeader
                avatar={<Avatar src={getLogo(props.wallet.currency).logo}></Avatar>}
                title={getLogo(props.wallet.currency).name}
                action={<a rel="noopener noreferrer" className='mt-3' href={getBlockChainUrl(props.wallet.currency) + props.wallet.address} target="_blank"><OpenInNew /></a>}
                subheader={
                    <TextField
                        fullWidth
                        id={props.wallet.address}
                        value={props.wallet.address}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <FileCopyIcon color="secondary" onClick={() => CopyMe(props.wallet.address)} />
                                </InputAdornment>
                            ),
                        }}
                    />
                }
            />
        </Card>
    );
}