import { Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CancelIcon from '@material-ui/icons/Cancel';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import clsx from 'clsx';
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { decodeToken, IsNullOrEmpty } from '../../helpers/utility';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { logoutRequest } from '../../store/authentication/auth.actions';
import store from '../../store/store';
import { LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ArrowDownward, ArrowUpward, PermIdentityOutlined, SwapHoriz } from '@material-ui/icons';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    closeIcon: {
        position: 'fixed',
        left: 240,
        background: theme.palette.secondary.main,
        borderRadius: 0,
        borderBottomRightRadius: 20,
        color: theme.palette.background.default
    },
    appBar: {
        background: theme.palette.primary.dark,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    list: {
        paddingTop: theme.spacing(0)
    },
    drawerPaper: {
        width: drawerWidth,
        color: theme.palette.common.white
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        minHeight: '40px !important'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3, 0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        marginBottom: theme.spacing(6)
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    title: {
        flexGrow: 1,
    },
    link: {
        textDecoration: "none",
        background: theme.palette.secondary.dark,
        display: "block",
        color: "white !important",
        borderBottom: '1px solid'
    },
    avatar: {
        textAlign: "center",
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary.dark
    },
    avathar: {
        fontSize: 72,
        color: theme.palette.divider
    }
}));

export default function NavBar(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { loading } = useSelector((state) => {
        return {
            loading: state.SettingReducer.isLoading ||
                state.AuthenticationReducer.isLoading ||
                state.UserReducer.isLoading
        }
    })

    const [anchorEl, setAnchorEl] = React.useState(null);
    const menOpen = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        store.dispatch(logoutRequest());
        setAnchorEl(null);
    };
    let token = sessionStorage.getItem('x-wallet-payment')?.toString();
    let dec = decodeToken();
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={IsNullOrEmpty(token) ? undefined : handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Wallet Payment
                    </Typography>
                    {
                        IsNullOrEmpty(token) ?
                            undefined :
                            <>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={menOpen}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircleIcon />
                                </IconButton>
                            </>
                    }
                </Toolbar>
                {
                    loading ? <LinearProgress color="secondary" /> : <span />
                }
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <IconButton className={classes.closeIcon} onClick={handleDrawerClose}>
                    <CancelIcon />
                </IconButton>
                <div className={classes.avatar} >
                    <AccountCircleIcon className={classes.avathar} color="secondary" />
                    <Typography variant="subtitle1" component="p">{dec ? dec["email"] : ''}</Typography>
                    <Typography variant="subtitle2" component="p"> Admin</Typography>

                </div>
                <List className={classes.list}>
                    <Link to={"/"} className={classes.link}>
                        <ListItem button>
                            <ListItemIcon style={{ minWidth: 32 }}><DashboardIcon htmlColor="#FFFFFF" /></ListItemIcon>
                            <ListItemText primary={"Dashboard"} />
                        </ListItem>
                    </Link>
                    <Link to={"/customers"} className={classes.link}>
                        <ListItem button>
                            <ListItemIcon style={{ minWidth: 32 }}><PeopleAltIcon htmlColor="#FFFFFF" /></ListItemIcon>
                            <ListItemText primary={"Customers"} />
                        </ListItem>
                    </Link>
                    <Link to={"/whitelists"} className={classes.link}>
                        <ListItem button>
                            <ListItemIcon style={{ minWidth: 32 }}><PermIdentityOutlined htmlColor="#FFFFFF" /></ListItemIcon>
                            <ListItemText primary={"Whitelists"} />
                        </ListItem>
                    </Link>
                    <Link to={"/swaps"} className={classes.link}>
                        <ListItem button>
                            <ListItemIcon style={{ minWidth: 32 }}><SwapHoriz htmlColor="#FFFFFF" /></ListItemIcon>
                            <ListItemText primary={"Swap Requests"} />
                        </ListItem>
                    </Link>
                    <Link to={"/withdraws"} className={classes.link}>
                        <ListItem button>
                            <ListItemIcon style={{ minWidth: 32 }}><ImportExportIcon htmlColor="#FFFFFF" /></ListItemIcon>
                            <ListItemText primary={"Withdrawals"} />
                        </ListItem>
                    </Link>
                    <Link to={"/transactions"} className={classes.link}>
                        <ListItem button>
                            <ListItemIcon style={{ minWidth: 32 }}><TransferWithinAStationIcon htmlColor="#FFFFFF" /></ListItemIcon>
                            <ListItemText primary={"Transactions"} />
                        </ListItem>
                    </Link>
                    <Link to={"/deposits"} className={classes.link}>
                        <ListItem button>
                            <ListItemIcon style={{ minWidth: 32 }}><AccountBalanceIcon htmlColor="#FFFFFF" /></ListItemIcon>
                            <ListItemText primary={"Deposits"} />
                        </ListItem>
                    </Link>
                    <Link to={"/settings"} className={classes.link}>
                        <ListItem button>
                            <ListItemIcon style={{ minWidth: 32 }}><SettingsIcon htmlColor="#FFFFFF" /></ListItemIcon>
                            <ListItemText primary={"Settings"} />
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                {props.children}
            </main>
        </div >
    );
}

NavBar.propTypes = {
    children: PropTypes.node,
};

